:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

#root {
	width: unset !important;
	min-width: unset !important;
	height: unset !important;
}

/*TODO: Keep this for turning on dark-mode based on OS preference*/
/*@media (prefers-color-scheme: dark) {*/
/*    :root {*/
/*        --foreground-rgb: 255, 255, 255;*/
/*        --background-start-rgb: 0, 0, 0;*/
/*        --background-end-rgb: 0, 0, 0;*/
/*    }*/
/*}*/

body {
	font-family: "Rubik";
	color: rgb(var(--foreground-rgb));
	background-color: #f8faff;
}

@layer base {
	html {
		font-family: "Rubik", system-ui, sans-serif;
	}
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

@tailwind base;
@tailwind components;
@tailwind utilities;
